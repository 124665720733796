import React from "react";
import { Layout } from "../components/layout";
import { Container, Typography, Box } from "@mui/material";
import { PageProps, graphql } from "gatsby";
import { Trans, Link, useI18next } from "gatsby-plugin-react-i18next";

const StudyNoTrans = (props: PageProps) => {
  const { defaultLanguage } = useI18next();
  return (
    <Layout>
      <Container maxWidth="lg">
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            <Trans>No translation available</Trans>
          </Typography>
          <Typography
            variant="body1"
            align="center"
            color="text.secondary"
            paragraph
          >
            <Link
              to={"/" + props.location.pathname.split("/").slice(2).join("/")}
              language={defaultLanguage}
            >
              <Trans>Go to english description</Trans>
            </Link>
          </Typography>
        </Box>
      </Container>
    </Layout>
  );
};

export default StudyNoTrans;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
